<template>
  <div class="animated fadeIn">
    <b-card>
      <form @submit.prevent="validateBeforeSubmit">
        <h1>Profile</h1>
        <b-row>
          <b-col md="6">
            <label class="">First Name</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text"  v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
            </b-input-group>
            <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>
          </b-col>
          <b-col md="6">
            <label class="">Last Name</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text"  v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
            </b-input-group>
            <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>
          </b-col>
        </b-row>
        <hr>

        <b-row>
          <b-col md="12">
            <label class="">Company Name</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text"  v-model="company_name" v-validate="'required'" name="company_name" class="form-control" placeholder="Company Name" />
            </b-input-group>
            <i v-show="errors.has('company_name')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('company_name')" class="help is-danger">The company name is required.</span>
          </b-col>
          <b-col md="6">
            <label class="">Address</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text"  v-model="addressline1" v-validate="'required'" name="addressline1" class="form-control" placeholder="Address Line 1" />
            </b-input-group>
            <i v-show="errors.has('addressline1')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addressline1')" class="help is-danger">The address is required.</span>
          </b-col>
          <b-col md="6">
            <label class="">Address 2</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text"  v-model="addressline2" name="addressline2" class="form-control" placeholder="Address Line 2" />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <label class="">City</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input  type="text" v-model="city" v-validate="'required'" name="city" class="form-control" placeholder="City" />
            </b-input-group>
            <i v-show="errors.has('city')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('city')" class="help is-danger">The city is required.</span>
          </b-col>
          <b-col md="6">
            <label class="">Province</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-select type="select"  :options="provinceOptions" v-model="province" v-validate="'required'" name="province" class="form-control" placeholder="Province"/>
            </b-input-group>
            <i v-show="errors.has('province')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('province')" class="help is-danger">The province is required. Max 6 characters.</span>
          </b-col>
          <b-col md="6">
            <label class="">Postal Code</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text"  v-model="postalcode" v-validate="'required|max:6'" name="postalcode" class="form-control" placeholder="Postal Code" />
            </b-input-group>
            <i v-show="errors.has('postalcode')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('postalcode')" class="help is-danger">The postal code is required.</span>
          </b-col>
          <b-col md="6">
            <label class="">Vendor ID Code</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="vidcode" v-validate="'required'" name="vidcode" class="form-control" placeholder="Vendor ID Code" />
            </b-input-group>
            <i v-show="errors.has('vidcode')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('vidcode')" class="help is-danger">The vendor id code is required.</span>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <label class="">Registration Company Number</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text"  v-model="rcn" v-validate="'required'" name="rcn" class="form-control" placeholder="Registration Company Number" />
            </b-input-group>
            <i v-show="errors.has('rcn')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('rcn')" class="help is-danger">The company number is required.</span>
          </b-col>

          <b-col md="6">
            <label class="">Vendor GST</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text"  v-model="gst" v-validate="'required'" name="gst" class="form-control" placeholder="Vendor GST Number" />
            </b-input-group>
            <i v-show="errors.has('gst')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('gst')" class="help is-danger">The vendor GST number is required.</span>
          </b-col>
        </b-row>

        <hr>
        <b-row>
          <b-col md="4">
            <label class="">Branch Number</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="bankbranch" v-validate="'required'" name="bankbranch" class="form-control" placeholder="Branch Number" />
            </b-input-group>
            <i v-show="errors.has('bankbranch')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('bankbranch')" class="help is-danger">The branch number is required.</span>
          </b-col>
          <b-col md="4">
            <label class="">Institution Number</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="bankins" v-validate="'required'" name="bankins" class="form-control" placeholder="Institution Number" />
            </b-input-group>
            <i v-show="errors.has('bankins')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('bankins')" class="help is-danger">The bank institution number is required.</span>
          </b-col>
          <b-col md="4">
            <label class="">Account Number</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="banknumber" v-validate="'required'" name="banknumber" class="form-control" placeholder="Account Number" />
            </b-input-group>
            <i v-show="errors.has('banknumber')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('banknumber')" class="help is-danger">The bank account number is required.</span>
          </b-col>
        </b-row>

        <hr>
        <b-row>
          <b-col md="12" class='mt-2'>
            <h5>Bank Account: {{bankbranch}}-{{bankins}}-{{banknumber}}</h5>
          </b-col>
        </b-row>

        <hr>
        <b-row>
          <b-col md="6">
            <label class="">Phone Number</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="number"  v-model="phone_num" v-validate="'required|digits:10'" name="phone_num" class="form-control" placeholder="Phone Number" />
            </b-input-group>
            <i v-show="errors.has('phone_num')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('phone_num')" class="help is-danger">{{ errors.first('phone_num') }}</span>
          </b-col>
          <b-col md="6">
            <label class="">Email Address</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-envelope"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text"  v-model="email" v-validate="'required|email'" name="email" class="form-control" placeholder="Email Address" />
            </b-input-group>
            <i v-show="errors.has('email')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('email')" class="help is-danger">The email is required.</span>
          </b-col>
        </b-row>
        <hr>
        <b-button variant="success" class="mt-4" type="submit" block>Edit Profile</b-button>

      </form>
    </b-card>
    <!-- <b-card>
        <h1>Company Logo</h1>
        <div class="mt-3 mb-3">
          <span v-if="!avatar">No Avatar</span> <img class="vendorlogo" v-bind:src="avatar" />
        </div>
        <b-row>
          <div class="col-md-4">
              <input class="ml-3 primary btn-sm" accept="image/x-png,image/jpeg" type="file" style="width: 190px" @change="onFileChanged($event)">
              <b-btn @click="uploadAvatar()" variant="primary btn-sm">Upload Avatar</b-btn>
          </div>
        </b-row>
    </b-card> -->
  </div>
</template>

<script>
import axios from 'axios'
import * as jwt_decode from 'jwt-decode';

export default {
  name: 'VendorEditProfile',
  data: function () {
    return {
      fname: '',
      lname: '',
      company_name: '',
      fax_num: '',
      phone_num: '',
      address: '',
      gst: '',
      rcn: '',
      bankaccount: '',
      bankins: '',
      banknumber: '',
      file: '',
      bankbranch: '',
      vidcode: '',
      error: false,
      province: null,
      city: '',
      addressline1: '',
      postalcode: '',
      addressline2: '',
      items: [],
      email: "",
      oldemail: "",
      avatar : null,
      provinceOptions: [
        { value: null, text: 'Please select a province' },
        { value: "Alberta", text: 'Alberta'},
        { value: "British Columbia", text: 'British Columbia'},
        { value: "Manitoba", text: 'Manitoba'},
        { value: "New Brunswick", text: 'New Brunswick'},
        { value: "Newfoundland and Labrador", text: 'Newfoundland and Labrador'},
        { value: "Nova Scotia", text: 'Nova Scotia'},
        { value: "Ontario", text: 'Ontario'},
        { value: "Prince Edward Island", text: 'Prince Edward Island'},
        { value: "Quebec", text: 'Quebec'},
        { value: "Saskatchewan", text: 'Saskatchewan'},
        { value: "Northwest Territories", text: 'Northwest Territories'},
        { value: "Nunavut", text: 'Nunavut'},
        { value: "Yukon", text: 'Yukon'}
      ]
    }
  },
  mounted: function () {
    this.$http.get('/vendor/get/profile')
    .then(response => {
      this.oldemail = response.data.email;
      this.email = response.data.email;
      this.fname = response.data.fname;
      this.lname = response.data.lname;
      this.company_name = response.data.company_name;
      this.fax_num = response.data.fax_num;
      this.phone_num = response.data.phone_num;
      this.addressline1 = response.data.addressline1;
      this.addressline2 = response.data.addressline2;
      this.city = response.data.city;
      this.postalcode = response.data.postalcode;
      this.province = response.data.province;
      this.gst = response.data.gst;
      this.rcn = response.data.rcn;
      this.vidcode = response.data.vidcode;
      this.bankins = response.data.bankaccount.split('-')[0];
      this.bankbranch = response.data.bankaccount.split('-')[1];
      this.banknumber = response.data.bankaccount.split('-')[2];


      let token = this.$session.get('jwt');
      var decoded = jwt_decode(token);

      //  Get the avatar
      this.$http.get('/get/vendor/avatar/' + decoded.id ).then(response => {
        this.avatar = response.data.avatar;
      }).catch(error => {
        console.log("Error getting avatar " + error);
      })

    })
    .catch(error => {
      this.$toasted.show('Error getting vendor profile', {type: 'error', duration: '3000'});
      console.log(error);
    })
  },
  methods: {
    onFileChanged(event){
      this.selectedAvatarToUpload = event.target.files[0]
      console.log("Got a new file");
      console.log(this.selectedAvatarToUpload);
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.bankaccount = this.bankins + "-" + this.bankbranch + "-" + this.banknumber;
          let data = JSON.stringify({
            fname: this.fname,
            lname: this.lname,
            company_name: this.company_name,
            vidcode: this.vidcode,
            fax_num: this.fax_num,
            phone_num: this.phone_num,
            postalcode: this.postalcode,
            addressline1: this.addressline1,
            addressline2: this.addressline2,
            city: this.city,
            province: this.province,
            gst: this.gst,
            rcn: this.rcn,
            bankaccount: this.bankaccount,
            oldemail: this.oldemail,
            newemail: this.email,
          })
          console.log(data)
          this.$http.post('/vendor/edit/profile', data)
          .then(response => {
            this.$toasted.show('Successfully Updated', {icon: 'fa-check', type: 'success', duration: '3000'})
            this.$router.go(-1)
          })
          .catch(error => {
            console.log(error)
            this.$toasted.show('Error. Please Try Again.', {type: 'error', duration: '3000'})
            this.error = true;
          })
          return;
        }
      });
    },
    async readSelectedFile(){
      var reader = new FileReader();
      return new Promise((resolve,reject) => {
        var file = this.selectedAvatarToUpload;

        reader.onerror = () => {
          reader.abort();
          reject("Somthing went wrong reading the file");
        }

        reader.onloadend = () => {
          console.log("read the file ");
          console.log(reader.result);
          resolve(reader.result);
        }

        reader.readAsDataURL(file);
      });
    },
    async uploadAvatar(){
      let avatar;

      try {
        avatar = await this.readSelectedFile();
      } catch (err) {
        this.$toasted.show('Avatar upload failed: Must be either .JPG .PNG and smaller than 110kb', {type: 'error', duration: '3000'});
        return;
      }

      let payload = {
        "avatar" : avatar
      }

      console.log("sending payload");
      console.log(payload);

      this.$http.post('vendor/edit/avatar',payload).then(response => {
        if (response.status === 200) {
          this.$toasted.show('Avatar Upload Success!', {type: 'success', duration: '3000'});
          this.avatar = avatar;
        } else {
          this.$toasted.show('Avatar upload failed: Must be either .JPG .PNG and smaller than 110kb', {type: 'error', duration: '3000'});
        }
      }).catch(error => {
        this.$toasted.show('Avatar upload failed: Must be either .JPG .PNG and smaller than 110kb', {type: 'error', duration: '3000'});
      })
    },


  }
}
</script>

<style>
    .vendorlogo{
      max-width: 150px;
      max-height: 150px;
    }
</style>
