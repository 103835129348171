var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c("b-card", [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.validateBeforeSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c("h1", [_vm._v("Profile")]),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("First Name")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-user" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "fname",
                            placeholder: "First Name"
                          },
                          model: {
                            value: _vm.fname,
                            callback: function($$v) {
                              _vm.fname = $$v
                            },
                            expression: "fname"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("fname"),
                          expression: "errors.has('fname')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle mb-2"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("fname"),
                            expression: "errors.has('fname')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The first name is required.")]
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("Last Name")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-user" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "lname",
                            placeholder: "Last Name"
                          },
                          model: {
                            value: _vm.lname,
                            callback: function($$v) {
                              _vm.lname = $$v
                            },
                            expression: "lname"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("lname"),
                          expression: "errors.has('lname')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle mb-2"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("lname"),
                            expression: "errors.has('lname')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The last name is required.")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("hr"),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "12" } },
                  [
                    _c("label", {}, [_vm._v("Company Name")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "company_name",
                            placeholder: "Company Name"
                          },
                          model: {
                            value: _vm.company_name,
                            callback: function($$v) {
                              _vm.company_name = $$v
                            },
                            expression: "company_name"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("company_name"),
                          expression: "errors.has('company_name')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("company_name"),
                            expression: "errors.has('company_name')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The company name is required.")]
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("Address")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "addressline1",
                            placeholder: "Address Line 1"
                          },
                          model: {
                            value: _vm.addressline1,
                            callback: function($$v) {
                              _vm.addressline1 = $$v
                            },
                            expression: "addressline1"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("addressline1"),
                          expression: "errors.has('addressline1')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("addressline1"),
                            expression: "errors.has('addressline1')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The address is required.")]
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("Address 2")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "addressline2",
                            placeholder: "Address Line 2"
                          },
                          model: {
                            value: _vm.addressline2,
                            callback: function($$v) {
                              _vm.addressline2 = $$v
                            },
                            expression: "addressline2"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("City")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "city",
                            placeholder: "City"
                          },
                          model: {
                            value: _vm.city,
                            callback: function($$v) {
                              _vm.city = $$v
                            },
                            expression: "city"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("city"),
                          expression: "errors.has('city')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("city"),
                            expression: "errors.has('city')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The city is required.")]
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("Province")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "select",
                            options: _vm.provinceOptions,
                            name: "province",
                            placeholder: "Province"
                          },
                          model: {
                            value: _vm.province,
                            callback: function($$v) {
                              _vm.province = $$v
                            },
                            expression: "province"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("province"),
                          expression: "errors.has('province')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("province"),
                            expression: "errors.has('province')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The province is required. Max 6 characters.")]
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("Postal Code")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:6",
                              expression: "'required|max:6'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "postalcode",
                            placeholder: "Postal Code"
                          },
                          model: {
                            value: _vm.postalcode,
                            callback: function($$v) {
                              _vm.postalcode = $$v
                            },
                            expression: "postalcode"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("postalcode"),
                          expression: "errors.has('postalcode')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("postalcode"),
                            expression: "errors.has('postalcode')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The postal code is required.")]
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("Vendor ID Code")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "vidcode",
                            placeholder: "Vendor ID Code"
                          },
                          model: {
                            value: _vm.vidcode,
                            callback: function($$v) {
                              _vm.vidcode = $$v
                            },
                            expression: "vidcode"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("vidcode"),
                          expression: "errors.has('vidcode')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("vidcode"),
                            expression: "errors.has('vidcode')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The vendor id code is required.")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("Registration Company Number")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "rcn",
                            placeholder: "Registration Company Number"
                          },
                          model: {
                            value: _vm.rcn,
                            callback: function($$v) {
                              _vm.rcn = $$v
                            },
                            expression: "rcn"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("rcn"),
                          expression: "errors.has('rcn')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle mb-2"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("rcn"),
                            expression: "errors.has('rcn')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The company number is required.")]
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("Vendor GST")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "gst",
                            placeholder: "Vendor GST Number"
                          },
                          model: {
                            value: _vm.gst,
                            callback: function($$v) {
                              _vm.gst = $$v
                            },
                            expression: "gst"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("gst"),
                          expression: "errors.has('gst')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle mb-2"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("gst"),
                            expression: "errors.has('gst')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The vendor GST number is required.")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("hr"),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "4" } },
                  [
                    _c("label", {}, [_vm._v("Branch Number")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "bankbranch",
                            placeholder: "Branch Number"
                          },
                          model: {
                            value: _vm.bankbranch,
                            callback: function($$v) {
                              _vm.bankbranch = $$v
                            },
                            expression: "bankbranch"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("bankbranch"),
                          expression: "errors.has('bankbranch')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle mb-2"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("bankbranch"),
                            expression: "errors.has('bankbranch')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The branch number is required.")]
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "4" } },
                  [
                    _c("label", {}, [_vm._v("Institution Number")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "bankins",
                            placeholder: "Institution Number"
                          },
                          model: {
                            value: _vm.bankins,
                            callback: function($$v) {
                              _vm.bankins = $$v
                            },
                            expression: "bankins"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("bankins"),
                          expression: "errors.has('bankins')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle mb-2"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("bankins"),
                            expression: "errors.has('bankins')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The bank institution number is required.")]
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "4" } },
                  [
                    _c("label", {}, [_vm._v("Account Number")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-building" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "banknumber",
                            placeholder: "Account Number"
                          },
                          model: {
                            value: _vm.banknumber,
                            callback: function($$v) {
                              _vm.banknumber = $$v
                            },
                            expression: "banknumber"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("banknumber"),
                          expression: "errors.has('banknumber')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle mb-2"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("banknumber"),
                            expression: "errors.has('banknumber')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The bank account number is required.")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("hr"),
            _c(
              "b-row",
              [
                _c("b-col", { staticClass: "mt-2", attrs: { md: "12" } }, [
                  _c("h5", [
                    _vm._v(
                      "Bank Account: " +
                        _vm._s(_vm.bankbranch) +
                        "-" +
                        _vm._s(_vm.bankins) +
                        "-" +
                        _vm._s(_vm.banknumber)
                    )
                  ])
                ])
              ],
              1
            ),
            _c("hr"),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("Phone Number")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", {
                                staticClass: "fa fa-phone fa-flip-horizontal"
                              })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|digits:10",
                              expression: "'required|digits:10'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            name: "phone_num",
                            placeholder: "Phone Number"
                          },
                          model: {
                            value: _vm.phone_num,
                            callback: function($$v) {
                              _vm.phone_num = $$v
                            },
                            expression: "phone_num"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("phone_num"),
                          expression: "errors.has('phone_num')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle mb-2"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("phone_num"),
                            expression: "errors.has('phone_num')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("phone_num")))]
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("label", {}, [_vm._v("Email Address")]),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-input-group-text", [
                              _c("i", { staticClass: "fa fa-envelope" })
                            ])
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "email",
                            placeholder: "Email Address"
                          },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("email"),
                          expression: "errors.has('email')"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle mb-2"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("email"),
                            expression: "errors.has('email')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("The email is required.")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("hr"),
            _c(
              "b-button",
              {
                staticClass: "mt-4",
                attrs: { variant: "success", type: "submit", block: "" }
              },
              [_vm._v("Edit Profile")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }